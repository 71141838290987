<template>
  <div>
    <div class="hero">
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
   </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import '../../assets/css/_industries.scss';
</style>
